body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-color{
  background-color: #cee741;
}
.logo-font{
  font-family: Bangers;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 50.6px;
}

.form-layout{
  margin: 6rem 30%;
  padding: 0;
}

.content-summary{
  word-wrap: break-word;
}